<template>
  <div class="item-data">
    <div class="station-name">
      <div class="station-name-info">
        <img src="../../assets/imgs/TaskOverview/park.png" />
        <span>{{ station.stationName }}</span>
      </div>
      <div v-show="station.vehicleData?.length === 0" class="no-data">
        暂无车辆前往
      </div>
    </div>
    <div v-show="station.vehicleData?.length > 0">
      <div class="station-vehicle-num">
        <div class="num-item m_r_6">
          <img src="../../assets/imgs/TaskOverview/sum.png" />
          <span>{{ station.sum }}</span>
        </div>
        <div class="num-item m_r_8">
          <img src="../../assets/imgs/TaskOverview/onPassage.png" />
          <span>{{ station.onTheWayNum }}</span>
        </div>
        <div class="num-item">
          <img src="../../assets/imgs/TaskOverview/arrive.png" />
          <span>{{ station.arriveNum }}</span>
        </div>
      </div>
      <div class="swipe-content">
        <van-swipe
          class="my-swipe"
          indicator-color="white"
          :loop="false"
          :stop-propagation="false"
        >
          <van-swipe-item
            v-for="(swipe, idx) in station.vehicleData"
            :key="'swipe' + idx"
          >
            <div class="vehicle-swipe">
              <div
                class="vehicle"
                v-for="(vehicle, index) in swipe"
                :key="'vehicle' + index"
                @click="vehicleClick(vehicle)"
              >
                <div class="vehicle-top">
                  <div class="vinId">{{ vehicle.vinId }}</div>
                  <div v-if="vehicle.battery !== null" class="electric">
                    <img src="../../assets/imgs/TaskOverview/battery.png" />{{
                      vehicle.battery
                    }}%
                  </div>
                </div>
                <div
                  class="status"
                  :class="{ 'status-complete': vehicle.missionStatus === 3 }"
                >
                  {{ vehicle.missionStatus === 3 ? '到达' : '在途' }}
                  <span
                    v-show="vehicle.missionStatus === 3"
                    class="complete-time"
                    >({{ timeFormatter(vehicle.arriveTime, 'HH:mm:ss') }})</span
                  >
                </div>
                <div v-if="vehicle.missionStatus === 3" class="arrive-time">
                  已停靠<span>{{ vehicle.stopTime }}</span
                  >分钟
                </div>
                <div
                  v-if="vehicle.missionStatus !== 3 && vehicle.arriveTime"
                  class="arrive-time"
                >
                  预计{{ timeFormatter(vehicle.arriveTime, 'HH:mm') }}到达
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { gotoVehicleDetail } from '@/utils/index';
export default {
  data() {
    return {};
  },
  props: {
    station: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    //
  },
  methods: {
    timeFormatter(time, timeFormatter) {
      return time ? moment(time).format(timeFormatter) : '-';
    },
    vehicleClick(vehicle) {
      gotoVehicleDetail(vehicle.vin, vehicle.online);
    },
  },
};
</script>

<style scoped lang="scss">
.m_r_6 {
  margin-right: 6px;
}
.m_r_8 {
  margin-right: 8px;
}
.item-data {
  margin: 0 21px 12px;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 14px 14px 8px 14px;
  box-sizing: border-box;
  .station-name {
    display: flex;
    // align-items: center;
    margin-bottom: 8px;
    justify-content: space-between;
    word-break: break-all;
    .station-name-info {
      display: flex;
      // align-items: baseline;
      flex: 1;
      img {
        width: 16px;
        height: 16px;
        object-fit: cover;
        margin-right: 7px;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        word-break: break-all;
      }
    }
    .no-data {
      width: 87px;
      text-align: right;
      font-size: 12px;
      font-weight: 400;
      color: rgba(151, 152, 171, 1);
    }
  }
  .station-vehicle-num {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .num-item {
      display: flex;
      align-items: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 2px;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
      }
    }
  }
  .vehicle-swipe {
    display: flex;
    flex-wrap: wrap;
    .vehicle {
      width: calc((100% - 16px) / 2);
      height: 79px;
      border-radius: 4px;
      background: rgba(245, 246, 249, 1);
      padding: 8px 0 9px 11px;
      margin-right: 8px;
      margin-bottom: 8px;
      .vehicle-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2px;
        padding-right: 8px;
        box-sizing: border-box;
        .vinId {
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
        }
        .electric {
          img {
            width: 16px;
            height: 16px;
            margin-right: 2px;
            vertical-align: sub;
          }
          font-size: 12px;
          font-weight: 500;
          color: rgba(151, 152, 171, 1);
        }
      }
      .status {
        font-size: 14px;
        font-weight: 600;
        color: rgba(66, 110, 255, 1);
        margin-bottom: 2px;
      }
      .status-complete {
        color: rgba(38, 197, 117, 1);
      }
      .complete-time {
        font-size: 12px;
        font-weight: 400;
        color: rgba(151, 152, 171, 1);
      }
      .arrive-time {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: rgba(151, 152, 171, 1);
        span {
          display: inline-block;
          max-width: 65px;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 隐藏溢出部分 */
          text-overflow: ellipsis; /* 显示省略号 */
          color: rgba(66, 110, 255, 1);
        }
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
::v-deep .van-swipe {
  padding-bottom: 5px;
  .van-swipe__indicators {
    bottom: 0;
  }
  .van-swipe__indicator {
    background: #aaaaaa !important;
    width: 5px;
    height: 5px;
  }
  .van-swipe__indicator--active {
    background: rgba(66, 110, 255, 1) !important;
  }
}
</style>
