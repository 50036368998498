var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{ref:"headerRef",staticClass:"header",attrs:{"id":"headerBox"}},[_c('div',{style:({ height: `${_vm.appBarHeight}px`, backgroundColor: 'white' })}),_c('div',{staticClass:"navBar"},[_c('img',{staticClass:"back",attrs:{"src":"https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png","alt":""},on:{"click":_vm.handleBack}}),_c('div',{staticClass:"title"},[_vm._v("到站情况")]),_c('div',{staticClass:"filter-box",on:{"click":_vm.filterClick}},[_c('img',{attrs:{"src":_vm.filterImg}})])])]),_c('div',{staticClass:"header-placehold",style:({
      height: `${_vm.headerH + _vm.appBarHeight}px`,
      backgroundColor: 'white',
    })}),_c('div',{ref:"contentBox",staticClass:"content",attrs:{"id":"contentBox"}},[_c('van-pull-refresh',{attrs:{"loosing-text":"释放刷新","pulling-text":"下拉刷新"},on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{style:({
          'min-height': `${_vm.minListHeight}px`,
          'padding-bottom': `${_vm.vanListPaddingBottom}px`,
        }),attrs:{"loading-text":'',"finished":_vm.finished,"offset":200,"immediate-check":false},on:{"load":_vm.onLoad},model:{value:(_vm.pullLoading),callback:function ($$v) {_vm.pullLoading=$$v},expression:"pullLoading"}},_vm._l((_vm.list),function(item){return _c('list-item',{key:item.id,attrs:{"station":item}})}),1)],1),(_vm.list.length == 0 && !_vm.isLoadingForEmpty)?_c('div',{staticClass:"noData"},[_c('img',{attrs:{"src":require("@/assets/VehicleTask/noData.png"),"alt":""}}),_c('p',{staticClass:"txt"},[_vm._v("暂无数据")])]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasCarHailingPermission),expression:"hasCarHailingPermission"}],staticClass:"call-plane",on:{"click":_vm.callClick}},[_c('img',{attrs:{"src":require("../../assets/imgs/TaskOverview/phone.png")}}),_c('span',[_vm._v("叫车")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }