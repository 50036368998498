<template>
  <div class="page">
    <div id="headerBox" ref="headerRef" class="header">
      <div
        :style="{ height: `${appBarHeight}px`, backgroundColor: 'white' }"
      ></div>
      <div class="navBar">
        <img
          class="back"
          @click="handleBack"
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
          alt=""
        />
        <div class="title">到站情况</div>
        <!-- <div class="title">
          到站情况{{ finished }}-{{ total }}-{{ filter.pageNo }}
        </div> -->
        <div class="filter-box" @click="filterClick">
          <img :src="filterImg" />
        </div>
      </div>
    </div>
    <div
      class="header-placehold"
      :style="{
        height: `${headerH + appBarHeight}px`,
        backgroundColor: 'white',
      }"
    ></div>
    <div id="contentBox" class="content" ref="contentBox">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        loosing-text="释放刷新"
        pulling-text="下拉刷新"
      >
        <van-list
          v-model="pullLoading"
          :loading-text="''"
          :finished="finished"
          :offset="200"
          :immediate-check="false"
          @load="onLoad"
          :style="{
            'min-height': `${minListHeight}px`,
            'padding-bottom': `${vanListPaddingBottom}px`,
          }"
        >
          <list-item
            v-for="item in list"
            :key="item.id"
            :station="item"
          ></list-item>
        </van-list>
      </van-pull-refresh>
      <div v-if="list.length == 0 && !isLoadingForEmpty" class="noData">
        <img src="@/assets/VehicleTask/noData.png" alt="" />
        <p class="txt">暂无数据</p>
      </div>
    </div>
    <div v-show="hasCarHailingPermission" class="call-plane" @click="callClick">
      <img src="../../assets/imgs/TaskOverview/phone.png" />
      <span>叫车</span>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { selectAreaData, taskViewHailVehicle } from '@/utils/index';
import listItem from './Item.vue';
import filterCommon from '../../assets/app/IntelligentDrivingManage/filter-common@2x.png';
import filterSelected from '../../assets/app/IntelligentDrivingManage/filter-selected@2x.png';
import { param2Obj } from '@/utils';
import { getStationMissionList, getrefreshNum } from '@/api/apiv2';

export default {
  components: {
    listItem: listItem,
  },
  data() {
    return {
      appBarHeight: parseInt(sessionStorage.getItem('_appBarHeight') ?? 0),
      headerH: 48,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      isLoading: false,
      isLoadingForEmpty: true, // 准确校验是否每次加载数据
      hasCarHailingPermission: 0, // false 无权限；true表示有权限
      // oldScrollTop: 0,   // 记录上一次滚动结束后的滚动距离
      // scrollTop: 0,       // 记录当前的滚动距离
      filter: {
        // pro: "",
        // city: "",
        parkCodes: [], //  园区code
        pageNo: 1,
        pageSize: 15,
        lat: 0,
        lng: 0,
      },
      filterStationIds: [], // 当前屏幕页面刷新用
      flutterFilter: null,
      filterImg: filterCommon,
      refreshNum: 100,
      refreshTimer: null, // 5s刷新当前屏幕的定时器
      scrollTop: 0, // 记录滑动位置
      total: 0,
      test: 0,
      listCountNum: 0, // 记录接口返回列表的实际数量,因为过滤调重复的了,所以list的数量跟接口的total不一致
    };
  },
  computed: {
    // 解决PullRefresh 的内容未填满屏幕时，只有一部分区域可以下拉
    minListHeight() {
      return this.list.length > 0
        ? document.body.clientHeight - this.headerH - this.appBarHeight - 20
        : 0;
    },
    vanListPaddingBottom() {
      return this.isLoadingForEmpty || !this.hasCarHailingPermission ? 0 : 70;
    },
    pullLoading: {
      get: function () {
        return !this.refreshing && this.loading;
      },
      set: function (val) {},
    },
  },
  watch: {},
  mounted() {
    const query = param2Obj(location.href);
    this.hasCarHailingPermission = parseInt(query.hasCarHailingPermission);
    this.filter.lat = query.userLat ?? 0; //  39.97920
    this.filter.lng = query.userLng ?? 0; // 116.495347
    // alert(location.href)
    window.getFlutterSelectArea = this.getFilterData;
    window.updateTaskView = this.carHailingSuccessFlag;
    this.getrefreshNumServer();
    if (process.env.NODE_ENV === 'development') {
      this.onLoad(); // 准时达App会有原生回调,所以脱离准时达App是调试需要打开此方法
    }
  },
  beforeDestroy() {
    window.getFlutterSelectArea = null;
    this.resetTimer();
  },
  methods: {
    handleBack() {
      window.location.href = 'neolix://more';
    },
    filterClick() {
      this.test = 0;
      selectAreaData();
    },
    callClick() {
      taskViewHailVehicle();
    },
    carHailingSuccessFlag() {
      this.$refs.contentBox.scrollTop = 0;
      this.onRefresh();
    },
    getFilterData(data) {
      // alert(JSON.stringify(data))
      if (this.isLoading) return;
      // this.filter.pro = data.province
      // this.filter.city = data.city
      let parkCodes = [];
      data.meshes.forEach((park) => {
        parkCodes.push(park.code);
      });
      this.filter.parkCodes = parkCodes;
      if (parkCodes.length > 0) {
        this.filterImg = filterSelected;
      } else {
        this.filterImg = filterCommon;
      }
      this.list = [];
      this.filterStationIds = [];
      this.filter.pageNo = 1;
      this.$loadingCircle.start();
      this.finished = false;
      this.onLoad();
    },
    async onLoad() {
      if (this.isLoading) return;
      this.isLoading = true;
      this.loading = true;
      this.isLoadingForEmpty = true;
      if (!this.refreshing && this.list.length === 0) {
        this.loading = false;
        this.$loadingCircle.start();
      }
      // alert(JSON.stringify(this.filter))
      // this.test++
      this.resetTimer();
      const responseData = await getStationMissionList(this.filter);
      if (responseData.code === '10000') {
        const res = responseData.data;
        if (this.refreshing) {
          this.list = [];
          this.filterStationIds = [];
          this.refreshing = false;
        }
        // const stationIds = []
        res.records.map((station) => {
          // 给接口兜底,过滤重复问题
          if (!this.list.some((s) => s.stationId === station.stationId)) {
            const vehicleData = [];
            // let tmpe = [...station.vehicleData, ...station.vehicleData, ...station.vehicleData, ...station.vehicleData, ...station.vehicleData, ...station.vehicleData]
            // for (let i = 0; i < tmpe.length; i = i + 4) {
            //   vehicleData.push(tmpe.slice(i, i + 4))
            // }
            for (let i = 0; i < station.vehicleData.length; i = i + 4) {
              vehicleData.push(station.vehicleData.slice(i, i + 4));
            }
            station.vehicleData = vehicleData;
            this.filterStationIds.push(station.stationId);
            this.list.push(station);
          }
        });
        // this.list = this.list.concat(res.records)
        // this.filterStationIds = this.filterStationIds.concat(stationIds)
        this.listCountNum += res.records.length;
        if (this.list.length >= res.total || res.records.length === 0) {
          this.finished = true;
        } else {
          this.filter.pageNo++;
        }
        this.total = res.total;
      } else {
        this.finished = true;
        Toast({
          message: responseData.msg,
          className: 'response-toast',
          forbidClick: true,
        });
      }
      this.isLoading = false;
      this.loading = false;
      this.isLoadingForEmpty = false;
      this.$loadingCircle.end();
      // 开始定时器请求
      this.timedRefresh();
    },
    // 下拉刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.filter.pageNo = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.refreshing = true;
      this.onLoad();
    },
    async getrefreshNumServer() {
      const res = await getrefreshNum();
      this.refreshNum = res ?? 100;
    },
    // 定时刷新
    timedRefresh() {
      // 超过100个站点不请求
      if (
        this.filterStationIds.length === 0 ||
        this.filterStationIds.length > this.refreshNum
      )
        return;
      this.refreshTimer = setInterval(() => {
        getStationMissionList({
          stationIds: this.filterStationIds,
        }).then((res) => {
          if (res.code === '10000' && res.data?.records?.length > 0) {
            res.data.records.forEach((station) => {
              let idx = this.list.findIndex(
                (s) => s.stationId === station.stationId
              );
              if (idx > -1) {
                const vehicleData = [];
                for (let i = 0; i < station.vehicleData.length; i = i + 4) {
                  vehicleData.push(station.vehicleData.slice(i, i + 4));
                }
                // let tmpe = [...station.vehicleData, ...station.vehicleData, ...station.vehicleData, ...station.vehicleData, ...station.vehicleData, ...station.vehicleData]
                // for (let i = 0; i < tmpe.length; i = i + 4) {
                //   vehicleData.push(tmpe.slice(i, i + 4))
                // }
                station.vehicleData = vehicleData;
                this.$set(this.list, idx, station);
              }
            });
          }
        });
      }, 5000);
    },
    windowScroll(e) {
      this.scrollTop = e.target.scrollTop;
    },
    resetTimer() {
      if (this.refreshTimer) {
        clearInterval(this.refreshTimer);
        this.refreshTimer = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  position: relative;
  background: rgba(245, 246, 250, 1);
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
  display: flex;
  flex-direction: column;

  .header {
    width: 100vw;
    position: fixed;
    z-index: 1;
    background: white;
    top: 0;
    .navBar {
      height: 48px;
      line-height: 48px;
      position: relative;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      font-weight: bold;

      .back {
        width: 0.64rem;
        height: 0.64rem;
        position: absolute;
        top: 0.07rem;
        left: 0.2rem;
        z-index: 3;
      }
      .title {
        font-size: 15px;
        font-weight: 600;
      }
      .filter-box {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .header-placehold {
    width: 100%;
    height: 48px;
    background: rgba(245, 246, 250, 1);
  }
  .content {
    flex: 1;
    overflow: scroll;
    padding-top: 12px;
  }
  .call-plane {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 102px;
    height: 38px;
    border-radius: 30px;
    background: rgba(70, 95, 253, 1);
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    padding-left: 22px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 11px;
    }
  }
  .noData {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;

    img {
      width: 3.2rem;
      height: 3.2rem;
    }
    .txt {
      font-size: 0.32rem;
      color: #000000;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  ::v-deep .van-loading {
    .van-loading__spinner {
      background: url(../../assets/imgs/loading2.gif) no-repeat center;
      background-size: 20px;
      animation: none;
      width: 20px !important;
      height: 20px !important;
      .van-loading__circular {
        display: none;
      }
    }
    .van-loading__text {
      display: none;
    }
  }
}
</style>
